<template>
  <div class="page-checkout m-5">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Renseignez vos information de livraison</h1>
      </div>

      <div class="column is-12 box">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Produits</th>
              <th>Prix</th>
              <th>Quantité</th>
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in cart.items" v-bind:key="item.product.id">
              <td>{{ item.product.name }}</td>
              <td>{{ item.product.price }} CFA</td>
              <td>{{ item.quantity }}</td>
              <td>{{ getItemTotal(item).toFixed(3) }} CFA</td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="2">Total</td>
              <td>{{ cartTotalLength }}</td>
              <td>{{ cartTotalPrice.toFixed(3) }} CFA</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="column is-12 box">
        <h2 class="subtitle">Détails de la livraison</h2>

        <p class="has-text-grey mb-4">* Tous les champs sont requis</p>

        <div class="columns is-multiline">
          <div class="column is-6">
            <div class="field">
              <label class="info-user">Nom*</label>
              <div class="control">
                <input type="text" class="input" v-model="first_name" />
              </div>
            </div>

            <div class="field">
              <label class="info-user">Prenom*</label>
              <div class="control">
                <input type="text" class="input" v-model="last_name" />
              </div>
            </div>

            <div class="field">
              <label class="info-user">E-mail*</label>
              <div class="control">
                <input type="email" class="input" v-model="email" />
              </div>
            </div>

            <div class="field">
              <label class="info-user">Téléphone*</label>
              <div class="control">
                <input type="text" class="input" v-model="phone" />
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="field">
              <label class="info-user">Region*</label>
              <div class="control">
                <input type="text" class="input" v-model="state" />
              </div>
            </div>

            <div class="field">
              <label class="info-user">Ville/Commune*</label>
              <div class="control">
                <input type="text" class="input" v-model="city" />
              </div>
            </div>

            <div class="field">
              <label class="info-user">Quartier*</label>
              <div class="control">
                <input type="text" class="input" v-model="district" />
              </div>
            </div>
          </div>
        </div>

        <div class="notification is-danger mt-4" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>

        <hr/>

        <div id="card-element" class="mb-5"></div>

        <template v-if="cartTotalLength">
          <hr />

          <button class="button" @click="submitForm">
            Payez a la livraison
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "Checkout",
  props: {
    initialItem: Object,
  },

  data() {
    return {
      item: this.initialItem,
      cart: {
        items: [],
      },
      stripe: {},
      card: {},
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      state: "",
      city: "",
      district: "",
      errors: [],
    };
  },
  mounted() {
    document.title = "Acheter | Aquamarine";
    this.cart = this.$store.state.cart;
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    submitForm() {
      this.errors = [];
      if (this.first_name === "") {
        this.errors.push("Vous devez mentionner votre nom");
      }
      if (this.last_name === "") {
        this.errors.push("Vous devez mentionner votre Prenom");
      }
      if (this.email === "") {
        this.errors.push("Vous devez mentionner votre Email");
      }
      if (this.phone === "") {
        this.errors.push("Vous devez mentionner votre numéro de téléphone");
      }
      if (this.state === "") {
        this.errors.push("Vous devez mentionner votre Region");
      }
      if (this.city === "") {
        this.errors.push("Vous devez mentionner votre Commune/Ville");
      }
      if (this.district === "") {
        this.errors.push("Vous devez mentionner votre quartier");
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        this.stripeTokenHandler();
        
      }
    },
    async stripeTokenHandler() {
      const items = []
      for (let i = 0; i < this.cart.items.length; i++) {
        const item = this.cart.items[i];
        const obj = {
          product: item.product.id,
          quantity: item.quantity,
          price: item.product.price * item.quantity
        };
        items.push(obj);
      }
      const data = {
        'first_name': this.first_name,
        'last_name': this.last_name,
        'email': this.email,
        'state': this.state,
        'city': this.city,
        'district': this.district,
        'phone': this.phone,
        'items': items
      };
      await axios
        .post("/api/v1/checkout/", data)
        .then((response) => {
          this.$store.commit("clearCart");
          toast({
            message: "Votre commande à été bien enregistré",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 10000,
            position: "top-center",
          });
          setTimeout(function () {
            window.location.reload(1);
          },10000);
          this.$router.push("/my-account");
        })
        .catch((error) => {
          this.errors.push("Un problème est survenu. Ressayez !");
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
  computed: {
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.product.price * curVal.quantity);
      }, 0);
    },
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
  },
};
</script>
<style scoped>
.info-user{
  color: black;
}
.button{
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    background-color: #ff00fe;
    color: #fff;
}
</style>