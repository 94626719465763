<template>
    <div class="page-cart m-5">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Votre panier</h1>
            </div>

            <div class="column is-12 box">
                <p v-if="cartTotalLength" class="cart-message"><i class="far fa-kiss-wink-heart"></i> Vous allez adorer</p>
                <table class="table is-fullwidth" v-if="cartTotalLength">
                    <thead>
                        <tr>
                            <th>Produits</th>
                            <th>Prix</th>
                            <th>Quantité</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <CartItem
                            v-for="item in cart.items"
                            v-bind:key="item.product.id"
                            v-bind:initialItem="item"
                            v-on:removeFromCart="removeFromCart" />
                    </tbody>
                </table>

                <p v-else class="cart-message"><i class="far fa-surprise"></i> Hooo! votre panier est vide.</p>
            </div>

            <div class="column is-12 box">
                <h2 class="subtitle">Résumé</h2>

                <strong>{{ cartTotalPrice.toFixed(3) }} CFA</strong>, {{ cartTotalLength }} Produits

                <hr>

                <router-link to="/cart/checkout" class="button">Acheter</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CartItem from '@/components/CartItem.vue'
export default {
    name: 'Cart',
    components: {
        CartItem
    },
    data() {
        return {
            cart: {
                items: []
            }
        }
    },
    mounted() {
        this.cart = this.$store.state.cart
    },
    methods: {
        removeFromCart(item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
        }
    },
    computed: {
        cartTotalLength() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        cartTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 0)
        },
    }
}
</script>
<style scoped>
.cart-message {
    font-size: 2rem;
}

.fa-grin-hearts{
    font-size: 25px;
    color: #ff00fe;
}
.button{
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    background-color: #ff00fe;
    color: #fff;
}
</style>