<template>
  <tr>
    <td>
      <router-link :to="item.product.get_absolute_url">
          <p class="cart-item-name">{{item.product.name}}</p>
      </router-link>
    </td>
    <td>{{ item.product.price }} CFA</td>
    <td>
      <a class="item-quantity">{{ item.quantity }}</a>
      <a @click="decrementQuantity(item)" class="minus-square">
          <i class="far fa-minus-square"></i>
      </a>
      <a @click="incrementQuantity(item)" class="cart-plus">
          <i class="fas fa-cart-plus"></i>
      </a>
    </td>
    <td>{{ getItemTotal(item).toFixed(3) }} CFA</td>
    <td>
        <a @click="removeFromCart(item)" class="item-delete">
            <i class="fas fa-trash"></i>
        </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    initialItem: Object,
  },
  data() {
    return {
      item: this.initialItem,
    };
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    decrementQuantity(item) {
      item.quantity -= 1;
      if (item.quantity === 0) {
        this.$emit("removeFromCart", item);
      }
      this.updateCart();
    },
    incrementQuantity(item) {
      item.quantity += 1;
      this.updateCart();
    },
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
    },
    removeFromCart(item) {
      this.$emit("removeFromCart", item);
      this.updateCart();
    },
  },
};
</script>

<style scoped>
.cart-item-name{
    color: #ff00fe;
}
.cart-plus{
    font-size: 25px;
    color: #ff00fe;
}
.minus-square{
    font-size: 25px;
    color: #ff00fe;
}
.item-delete{
    font-size: 25px;
    color: #ff00fe;
}
.item-quantity{
    font-size: 25px;
    margin-right: 5px;
    color: rgb(45, 3, 45);
}
</style>