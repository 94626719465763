<template>
    <div class="box mb-4">
        <!--<h3 class="is-size-4 mb-6">Commande numero {{ order.id }}</h3>-->
        <h3 class="is-size-4 mb-6">Résumé de vos commandes</h3>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Produit</th>
                    <th>Prix</th>
                    <th>Quantité</th>
                    <th>Total</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="item in order.items"
                    v-bind:key="item.product.id">
                    <td>{{ item.product.name }}</td>
                    <td>{{ item.product.price }} CFA</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ getItemTotal(item).toFixed(3) }} CFA</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'OrderSummary',
    props: {
        order: Object
    },
    methods: {
        getItemTotal(item) {
            return item.quantity * item.product.price
        },
        orderTotalLength(order) {
            return order.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
    }
}
</script>
