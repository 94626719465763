<template>
  <div class="page-my-account m-5">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Mon compte</h1>
      </div>

      <div class="column is-12">
        <button @click="logout()" class="button is-danger">Déconnexion</button>
      </div>

      <hr/>

      <div class="column is-12">
        <h2 class="subtitle">Mes achats</h2>

        <OrderSummary
          v-for="order in orders"
          v-bind:key="order.id"
          v-bind:order="order"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OrderSummary from "@/components/OrderSummary.vue";
import {toast} from "bulma-toast";

export default {
  name: "MyAccount",
  components: {
    OrderSummary,
  },
  data() {
    return {
      orders: [],
    };
  },
  mounted() {
    document.title = "Mon compte | aqua-marine";
    this.getMyOrders();
  },

  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");
      toast({
        message: "Vous êtes déconnecté",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 10000,
        position: "top-center",
      });
      this.$router.push("/");
    },
    async getMyOrders() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/orders/")
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>