<template>
  <div class="container">
    <h3 class="is-size-3 has-text-centered">
      <strong>Inscrivez-vous</strong>
    </h3>

    <div class="box">
      <div class="columns">
        <div class="column">
          <figure class="image is-16by9 is-covered">
            <img
              src="https://aqua-marine-bucket.s3.us-east-2.amazonaws.com/aquarium-animation.gif"
            />
          </figure>
          <p
            class="has-text-black paragraph has-text-centered"
            style="padding-top: 10px"
          >
            Votre première boutique d'aquarophilie digital en Côte d'Ivoire
            <i class="fas fa-fish" style="color: #ee82ee"></i>
          </p>
        </div>

        <div class="column">
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label">Votre nom d'utilisateur</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  placeholder="Votre nom d'utilisateur"
                  v-model="username"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Votre mot de passe</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Votre mot de passe"
                  v-model="password"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Confirmez votre mot de passe</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Votre mot de passe"
                  v-model="password2"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
              </div>
              <div class="notification is-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>

              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    Si vous avez une compte, cliquez sur le bouton connexion pour
                    vous conenecter
                  </label>
                </div>
              </div>

              <div class="field is-grouped">
                <div class="control">
                  <button class="button is-purple">Inscription</button>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div class="field is-grouped mt-4">
            <div class="control">
              <a>
                <router-link class="button is-purple" to="/log-in">
                  Connexion
                </router-link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "SignUp",
  data() {
    return {
      username: "",
      password: "",
      password2: "",
      errors: [],
    };
  },
  methods: {
    submitForm() {
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Le nom d'utilisateur ne peut être vide");
      }

      if (this.password !== this.password2) {
        this.errors.push("Les mots de passe ne sont pas similaire");
      }
      if (this.password === this.username) {
        this.errors.push(
          "Le mot de passe ne doit pas être similaire au nom d'utilisateur"
        );
      }

      if (this.password.length < 8) {
        this.errors.push("Le mot de passe doit être inférieur à 8 charactères");
      }
      if (!isNaN(this.password)) {
        this.errors.push(
          "Le mot de passe ne doit pas être uniquement numérique"
        );
      }
      if (!this.errors.length) {
        const formData = {
          username: this.username,
          password: this.password,
        };
        axios
          .post("/api/v1/users/", formData)
          .then((response) => {
            toast({
              message: "Votre compte à été créé avec succes!",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 10000,
              position: "top-center",
            });
            this.$router.push("/log-in");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(
                  //`${property}: ${error.response.data[property]}`
                  "Ce nom d'utilisateur existe déjà"
                );
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors.push("Un problème est survenu. Veuillez réessayer");
              console.log(errors);

              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.info-user {
  color: black;
}
.hero-body {
  background: linear-gradient(
    90deg,
    rgba(34, 0, 36, 0.7609418767507002) 0%,
    rgba(80, 6, 99, 0.8393732492997199) 33%,
    rgba(134, 0, 255, 0.5872724089635855) 100%
  );
  border-radius: 10px;
}
.title-subtitle-welcome {
  color: white;
}
.button.is-purple {
  background-color: #ff00fe !important;
  color: white !important;
}

.button.is-purple:hover {
  background-color: white !important;
  color: #ff00fe !important;
}
.button.is-purple:hover {
  background-color: white !important;
  color: black !important;
}
</style>
