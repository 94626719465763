<template>
  <div class="container">
    <h3 class="is-size-3 has-text-centered">
      <strong>Connectez-vous</strong>
    </h3>

    <div class="box">
      <div class="columns">
        <div class="column">
          <figure class="image is-16by9 is-covered">
            <img
              src="https://aqua-marine-bucket.s3.us-east-2.amazonaws.com/aquarium-animation.gif"
            />
          </figure>
          <p
            class="has-text-black paragraph has-text-centered"
            style="padding-top: 10px"
          >
            Votre première boutique d'aquarophilie digital en Côte d'Ivoire
            <i class="fas fa-fish" style="color: #ee82ee"></i>
          </p>
        </div>

        <div class="column">
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label">Votre nom d'utilisateur</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  placeholder="Votre nom d'utilisateur"
                  v-model="username"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Votre mot de passe</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Votre mot de passe"
                  v-model="password"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
              </div>
              <div class="notification is-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>

              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    Si vous n'avez pas de compte cliquez sur le bouton
                    inscription pour vous inscrire
                  </label>
                </div>
              </div>

              <div class="field is-grouped">
                <div class="control">
                  <button class="button is-purple">Connexion</button>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div class="field is-grouped mt-4">
            <div class="control">
              <a>
                <router-link class="button is-purple" to="/sign-up">
                  Inscription
                </router-link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {toast} from "bulma-toast";
export default {
  name: "LogIn",
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },
  mounted() {
    document.title = "Connexion | Aquamarine";
  },
  methods: {
    async submitForm() {
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Le nom d'utilisateur ne peut être vide");
      }
      if (this.password === "") {
        this.errors.push("Le mot de passe ne peut être vide");
      }
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        username: this.username,
        password: this.password,
      };
      await axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          toast({
            message: "Vous êtes connecté",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 10000,
            position: "top-center",
          });
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
          const toPath = this.$route.query.to || "/cart";
          this.$router.push(toPath);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push("Entrez des informations de connexion correct");
            }
          } else {
            this.errors.push("Une erreur est survenu réessayez plus tard");

            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
<style scoped>
.button.is-purple {
  background-color: #ff00fe !important;
  color: white !important;
}

.button.is-purple:hover {
  background-color: white !important;
  color: #ff00fe !important;
}
.button.is-purple:hover {
  background-color: white !important;
  color: black !important;
}
.info-user {
  color: black;
}
</style>