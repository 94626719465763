<template>
  <div class="page-success">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="success-message">
          <h1 class="success-icon">
            <i class="fab fa-angellist"></i>
            <p class="sucess-subtitle">Commande éffectué</p>
          </h1>
        </div>
        <div class="notice info">
          <p>Votre commande à été éffectué avec succès</p>
        </div>

        <div class="notice success">
          <p>Notre équipe traitera votre commande</p>
        </div>

        <div class="notice warning">
          <p>Vous allez recevoir un appel sous peu</p>
        </div>

        <div class="notice error">
          <p>aqua-marine vous remercie</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  mounted() {
    document.title = "Acheter | Aquamarine";
    if (window.localStorage) {
      if (!localStorage.getItem("firstLoad")) {
        localStorage["firstLoad"] = true;
        window.location.reload();
      } else localStorage.removeItem("firstLoad");
    }
  }
};
</script>

<style scoped lang="scss">
.fa-angellist {
  font-size: 50px;
  color: #ff00fe;
}
.sucess-subtitle {
  font-size: 25px;
  color: #ff00fe;
}
.success-icon {
  text-align: center;
}

p {
  margin: 0;
}

.notice {
  position: relative;
  margin: 1em;
  background: #f9f9f9;
  padding: 1em 1em 1em 2em;
  border-left: 4px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.125);
}

.notice:before {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: -17px;
  background-color: #ddd;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info {
  border-color: #ff00fe;
}

.info:before {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  background-color: #ff00fe;
}

.success {
  border-color: #ff00fe;
}

.success:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0c0";
  background-color: #ff00fe
}

.warning {
  border-color: #ff00fe;
}

.warning:before {
  font-family: "Font Awesome 5 Free";
  content: "\f095";
  background-color: #ff00fe;
}

.error {
  border-color: #ff00fe;
}

.error:before {
  font-family: "Font Awesome 5 Free";
  content: "\f2b5";
  background-color: #ff00fe;
}
</style>
