<template>
  <div class="page-category m-5">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="is-size-4 has-text-centered">{{ category.name }}</h2>
      </div>

      <ProductBox
        v-for="product in category.products"
        v-bind:key="product.id"
        v-bind:product="product"/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import ProductBox from "@/components/ProductBox";
export default {
  name: "Category",
  components: {
    ProductBox,
  },
  data() {
    return {
      category: {
        products: [],
      },
    };
  },
  mounted() {
    this.getCategory();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  methods: {
    async getCategory() {
      const categorySlug = this.$route.params.category_slug;
      this.$store.commit("setIsLoading", true);
      axios
        .get(`/api/v1/products/${categorySlug}/`)
        .then((response) => {
          this.category = response.data;
          document.title = this.category.name + " | aqua-marine";
        })
        .catch((error) => {
          console.log(error);
          toast({
            message: "Pas de produit disponible pour le moment",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>