<template>
  <div id="wrapper" class="has-text-centered-mobile">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="control title has-text-centered">
            <button class="button is-large">
              <span class="has-text-weight-semibold">
                <form method="get" action="/search">
                  <div class="field has-addons">
                    <div class="control">
                      <input
                        type="text"
                        class="input"
                        placeholder="Recherche"
                        name="query"
                      />
                    </div>

                    <div class="control">
                      <button class="button search-btn">
                        <span class="icon">
                          <i class="fas fa-search"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </span>
            </button>
          </div>
          <h1 class="title has-text-centered">Bienvenu chez aqua-marine</h1>
          <video controls autoplay loop>
            <source
              src="https://aqua-marine-bucket.s3.us-east-2.amazonaws.com/video-aquarium.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="column">
          <p class="has-text-centered-mobile text-info">
            Aqua-marine est la première entreprise aquariophile digitale en Côte
            d’Ivoire. Notre boutique en ligne est un grand supermarché qui vous
            offre des produits illimités pour vos aquariums, et cela 7j/7j et
            24h/ 24h, même pendant les jours fériés. Avec La boutique en ligne
            d’aqua-marine, faites vos courses comme si nous n’étions pas là.<i
              class="far fa-smile-wink"
            ></i>
          </p>
          <hr class="rounded" />
          <div class="is-divider" data-content=""></div>

          <p class="subtitle is-3" style="margin-top: 20px">
            Ce que nous offrons
          </p>
          <p class="has-text-centered-mobile">
            <i class="fa fa-check" aria-hidden="true" style="color: #9932cc">
            </i>
            aquariums
          </p>
          <p class="has-text-centered-mobile">
            <i
              class="fa fa-check"
              aria-hidden="true"
              style="color: #9932cc"
            ></i>
            Aliments de poissons
          </p>
          <p class="has-text-centered-mobile">
            <i
              class="fa fa-check"
              aria-hidden="true"
              style="color: #9932cc"
            ></i>
            Poissons
          </p>
          <div class="control">
            <button class="button is-purple is-large">
              <span class="has-text-weight-semibold">
                <router-link to="/aquariums">
                  <span class="learn-more">En savoir plus</span>
                </router-link>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="columns is-multiline m-5">
    <div class="column is-12">
      <h1 class="is-size-4 has-text-centered title">Dernières nouveautés</h1>
    </div>

    <ProductBox
      v-for="product in latestProducts"
      v-bind:key="product.id"
      v-bind:product="product"
    />
  </div>
</template>

<script>
import axios from "axios";
import ProductBox from "@/components/ProductBox";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel";
import "bulma-carousel/dist/css/bulma-carousel.min.css";

export default {
  name: "Home",

  data() {
    return {
      latestProducts: [],
      promotionProducts: [],
    };
  },
  components: {
    ProductBox,
    bulmaCarousel,
  },
  mounted() {
    bulmaCarousel.attach("#carousel-demo", {
      initialSlide: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
    });
    this.getLatestProducts();
    this.getPromotionsProducts();
    document.title = "Vos aquariums | Aqua-Marine";
  },
  methods: {
    async getLatestProducts() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/latest-products/")
        .then((response) => {
          this.latestProducts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getPromotionsProducts() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/latest-products/")
        .then((response) => {
          this.promotionProducts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<style scoped lang="scss">
.home-annonce {
  color: #ff00fe;
}
.title {
  color: #ff00fe;
}
.text-info {
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 20px;
}

.fa-smile-wink {
  font-size: 25px;
}

.learn-more {
  color: white !important;
}

hr.rounded {
  border-top: 2px solid #c7b8ea;
  border-radius: 2px;
}

.sectionf {
  padding-top: 1.5rem;
}

#footer {
  padding: 0.5rem;
}

#users-feed {
  padding: 4rem 1.5rem;
}
</style>