<template>
  <div class="column is-3">
    <div class="box">
      <router-link v-bind:to="product.get_absolute_url">
        <figure class="image">
          <img v-bind:src="product.get_thumbnail" />
        </figure>
        <h3 class="is-size-4 product-name">{{ product.name }}</h3>
      </router-link>
      <p class="is-size-6 product-price">{{ product.price }} CFA</p>
      <a @click="addToCart()" class="button cart-button">AJOUTER AU PANIER</a>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
export default {
  name: "ProductBox",
  /* data() {
    return {
      product: {},
      quantity: 1,
    };
  },*/
  props: {
    product: Object,
  },
  methods: {
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      const item = {
        product: this.product,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);
      toast({
        message: "Produit ajouté à votre panier",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 5000,
        position: "top-center",
      });
    },
  },
};
</script>

<style scoped>
.image {
  margin: auto;
}
.cart-button {
  color: #fff;
  background-color: #470647;
  margin-top: 2px;
}
.product-name {
  color: #ff00fe;
}
.product-price {
  color: #470647;
  font-weight: bold;
}
</style>